<template>
  <div class="not-found-container">
    <h1>404</h1>
    <h2>Page Not Found</h2>
    <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
    <v-btn color="secondary" variant="tonal" @click="$router.go(-1)">Take Me Back</v-btn>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
}

.not-found-container h1 {
  font-size: 6rem;
  color: #dc3545;
}

.not-found-container h2 {
  font-size: 2.5rem;
  color: #343a40;
}

.not-found-container p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 20px;
}


</style>
