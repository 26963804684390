<script setup>
import { navigation } from "./store/navigation.js";
import { apptheme } from "./store/apptheme.js";
</script>

<template>
  <div id="app" v-bind:class="apptheme.theme_type">
    <v-card v-if="isLoading">
      <div class="gif-container">
        <img src="@/assets/Gifs/FLA_light.gif" alt="Animated GIF" />
      </div>
    </v-card>
    <v-card v-else>
      <FlashMessage
        :position="'right bottom'"
        style="position: fixed; z-index: 15000"
      ></FlashMessage>

      <v-layout>
        <div v-if="layout === 'default-layout'">
          <NavigationDrawer></NavigationDrawer>
          <v-app-bar color="white" elevation="3" style="border-radius: 0px">
            <template v-slot:prepend>
              <v-app-bar-nav-icon
                v-show="navigation.drawer === false"
                @click="navigation.setDrawer(!navigation.drawer)"
              ></v-app-bar-nav-icon>
              <div v-show="navigation.drawer === false">
                <div class="font-login text-center">
                  <div v-if="app_image_url">
                    <span>
                      <img
                        :src="envImagePath + app_image_url"
                        width="100"
                        height="50"
                      />
                    </span>
                  </div>
                  <div v-else-if="app_image_url == ''">
                    <span class="font-base-app text-center">
                      {{ application_name }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="font-base-app text-center">
                      {{ application_name }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <v-spacer></v-spacer>

            <!-- <v-tooltip
            :text="$t('notifications')"
            location="bottom"
            v-if="showtradienotification"
          > -->
            <!-- <template v-slot:activator="{ props }">
            
            </template> -->
            <div class="text-center">
              <v-menu
                v-model="notificationmenu"
                :close-on-content-click="false"
                location="bottom"
                max-height="650"
              >
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-if="showtradienotification"
                    plain
                    size="medium"
                    class="mr-5"
                    v-bind="props"
                    @click="shownotificationdialog()"
                  >
                    <v-badge
                      :content="notification_count"
                      color="error"
                      v-if="notification_count > 0"
                    >
                      <v-icon @click="shownotificationdialog()"
                        >mdi-bell-outline</v-icon
                      >
                    </v-badge>
                    <v-icon v-else @click="shownotificationdialog()"
                      >mdi-bell-outline</v-icon
                    >
                  </v-btn>
                </template>

                <v-card
                  class="notifcationmaincardscroll mx-auto mt-2"
                  width="600"
                  v-bind:class="[
                    apptheme.theme_type == 'theme-dark'
                      ? 'notificationdialogclassdark'
                      : '',
                  ]"
                >
                  <v-toolbar color="cyan-lighten-1">
                    <v-toolbar-title>{{ $t("notifications") }}</v-toolbar-title>
                    <v-tooltip :text="$t('cancel')" location="bottom">
                      <template v-slot:activator="{ props }">
                        <v-icon
                          @click="notificationmenu = false"
                          v-bind="props"
                          v-on="on"
                          class="mr-4"
                          >mdi-close</v-icon
                        >
                      </template>
                    </v-tooltip>
                  </v-toolbar>
                  <div v-if="notification_array.length > 0" class="pl-1">
                    <div
                      class="row"
                      v-for="(notification, index) in displayedItems"
                      :key="index"
                      v-bind:class="[index != 0 ? 'mt-2' : 'mt-0']"
                    >
                      <v-row style="border-bottom: solid #0000002b 1px">
                        <v-col
                          md="2"
                          lg="2"
                          sm="2"
                          class="text-center my-3 pl-6 align-self-center"
                          v-if="notification.fetchuserdetails"
                        >
                          <v-avatar
                            v-if="
                              notification.fetchuserdetails.image_url != null
                            "
                            size="50"
                            :image="
                              envImagePath +
                              notification.fetchuserdetails.image_url
                            "
                          ></v-avatar>
                          <v-avatar v-else size="50">
                            <v-img
                              :src="
                                require('@/assets/images/avatars/default.png')
                              "
                            ></v-img>
                          </v-avatar>
                        </v-col>
                        <v-col
                          md="10"
                          lg="10"
                          sm="10"
                          class="align-self-center"
                        >
                          <div class="row">
                            <div
                              class="notificationcreatedbyclass col-md-6 col-sm-6 col-lg-6 pt-2"
                            >
                              {{ notification.fetchuserdetails.full_name }}
                            </div>
                            <div
                              class="col-md-6 col-sm-6 col-lg-6 pt-1 text-end font-italic"
                            >
                              <span class="notificationsentonclass">{{
                                formatDateTime(notification.created_at)
                              }}</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="notficationtitleclass">
                              {{ notification.title }}
                            </div>
                            <div class="notficationdescriptionclass">
                              {{ notification.description }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      class="d-flex justify-content-center text-center mt-8 mb-5"
                    >
                      <v-btn
                        @click="loadMore"
                        class="green_btn_color"
                        size="small"
                        v-if="showLoadMoreButton"
                      >
                        {{ $t("load_more") }}
                      </v-btn>
                      <div v-else>
                        <h6
                          class="text-start font-weight-medium first_h6_section"
                        >
                          {{ $t("no_more_data") }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div v-else class="nonotificationclass">
                    <v-row>
                      <v-col md="12" sm="12" lg="12" class="text-center">
                        <span>{{ $t("no_notification") }}</span>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-menu>
            </div>
            <!-- <v-btn
              icon
              @click="
                switchTheme();
                apptheme.setTheme();
              "
            >
              <v-icon
                style="transition: 2s"
                :icon="
                  apptheme.theme_type == 'theme-dark'
                    ? 'mdi-weather-night'
                    : 'mdi-white-balance-sunny'
                "
              ></v-icon>
            </v-btn> -->
            <ProfileView @getuserdetails="fetchUserdetails"></ProfileView>
          </v-app-bar>
        </div>

        <v-main style="min-height: 100vh; background-color: #fffffffc">
          <router-view> </router-view>
        </v-main>
      </v-layout>
    </v-card>
  </div>
  <LogoutTimer></LogoutTimer>
</template>

<script>
import localStorageWrapper from "./localStorageWrapper.js";



const default_layout = "default";
import NavigationDrawer from "./Layout/NavigationDrawer.vue";
import ProfileView from "./Layout/ProfileView.vue";
import LogoutTimer from "./LogoutTimer.vue";
export default {
  components: { NavigationDrawer, ProfileView, LogoutTimer },
  data() {
    return {
      isLoading: true,
      notificationmenu: false,
      app_image_url: "",
      application_name: "",
      app_name: "",
      showtradienotification: false,
      notification_count: 0,
      user: [],
      user_id: "",
      notification_array: [],
      currentTheme: localStorage.getItem("theme-type"),
      envImagePath: process.env.VUE_APP_IMAGE_PATH,
      displayedItems: [],
      loader: false,
      currentIndex: 0,
      itemsPerPage: 5,
      currency_symbol: "",
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    nextItems() {
      return this.notification_array.slice(
        this.currentIndex,
        this.currentIndex + this.itemsPerPage
      );
    },
    showLoadMoreButton() {
      return this.currentIndex < this.notification_array.length;
    },
  },

  mounted() {
    this.setLoader();
  },
  created() {
    this.emitter.on("setLoader", () => {
      this.setLoader();
    });
    this.emitter.on("app_image_update", () => {
      this.fetchAppImageUrl();
    });
    this.getAppImage();
    this.fetchSysParam();
  },
  watch: {
  },

  methods: {
    fetchAppImageUrl() {
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "fetch_image_url", {})
        .then((res) => {
          this.app_image_url = res.data.parameter_image;
          if (this.app_image_url) {
            localStorageWrapper.setItem(
              "App_Image_Url",
              this.app_image_url.parameter_value
            );
            this.app_image_url = localStorage.getItem("App_Image_Url");
          } else {
            localStorageWrapper.removeItem("App_Image_Url");
            this.app_image_url = "";
          }
          this.emitter.emit("update_nav_image");
        })
        .catch((err) => {
          console.log("this error" + err);
        });
    },
    fetchSysParam() {
      this.$axios
        .get(process.env.VUE_APP_API_URL_ADMIN + "get_system_param", {
          params: {
            parameter_name: "CURRENCY_SYMBOL",
          },
        })
        .then((res) => {
          this.currency_symbol = res.data.parameter;
          localStorageWrapper.setItem("currency_symbol", this.currency_symbol);
          
        })
        .catch((err) => {
          console.log("this error" + err);
        });
    },
    setLoader() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },


 

    getAppImage() {
      if (localStorageWrapper.getItem("App_Image_Url") != null) {
        this.app_image_url = localStorageWrapper.getItem("App_Image_Url");
        this.app_name = localStorageWrapper.getItem("App_Name");
      } else {
        this.app_image_url = "";
      }
      if (localStorageWrapper.getItem("Application_Name")) {
        this.application_name = localStorageWrapper.getItem("Application_Name");
      } else {
        this.application_name = "";
      }
    },

    switchTheme() {
      const storedTheme = localStorage.getItem("theme-type");
      if (storedTheme === "theme-dark") {
        localStorage.setItem("theme-type", "theme-light");
        this.currentTheme = localStorage.getItem("theme-type");
      } else {
        localStorage.setItem("theme-type", "theme-dark");
        this.currentTheme = localStorage.getItem("theme-type");
      }
    },

    loadMore() {
      this.loader = true;
      this.displayedItems.push(...this.nextItems);
      this.currentIndex += this.itemsPerPage;
      if (!this.showLoadMoreButton) {
        console.log("All data loaded.");
      }
      this.loader = false;
    },
  },
};
</script>
<style>
nav {
  padding: 30px;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.notificationcreatedbyclass {
  font-size: 12px;
  font-weight: 500;
  color: #000000c9;
}

.notficationtitleclass {
  font-size: 16px;
  font-weight: 500;
}

.notficationdescriptionclass {
  font-size: 13px;
  text-align: justify;
}

.notificationsentonclass {
  color: rgb(7 7 7 / 55%);
  font-size: 14px;
  font-weight: 500;
}

.nonotificationclass {
  min-height: 68px;
  align-self: center;
  padding: 30px 0px;
  font-size: 20px;
  font-weight: 500;
}

.notificationdialogclassdark {
  background: #222 !important;
  color: #fff !important;
}

.notificationdialogclassdark .notificationcreatedbyclass {
  color: #ffffff91 !important;
}

.notificationdialogclassdark .notificationsentonclass {
  color: #ffffff91 !important;
}

.v-toolbar__content {
  height: 80px !important;
}

.v-toolbar-title__placeholder {
  font-size: 23px;
  font-weight: 500;
  font-family: "Roboto";
  color: #000000bd;
  padding-left: 10px;
}
.notifcationmaincardscroll {
  overflow-x: hidden !important;
}
.notifcationmaincardscroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.gif-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdd;
}
.gif-container img {
  width: 300px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
